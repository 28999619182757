import aut from './auth.module.scss';

import Footer2 from '../../components/footer2';
import Header3 from '../../components/header3';
import Notif from '../../components/notif';
import { useEffect, useState } from 'react';

export default function SiteIndex() {
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    let yu = window.localStorage.getItem('loggedOut');
    if (yu != null) {
      sN({ display: true, type: 'success', msg: `Logged out !` });
      setTimeout(() => {
        sN({ display: false, type: 'success', msg: `` });
        window.localStorage.removeItem('baUid');
        window.localStorage.removeItem('tempbaUid');
        window.localStorage.removeItem('account_verification');
        window.localStorage.removeItem('loggedOut');
      }, 5000);
    }
  }, []);

  return (
    <div>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />
      <Header3
        title="Sign In"
        onClick={() => {
          window.location.href = '/wp-user/auth/login';
        }}
      />

      <div className={aut.imc}>
        <div className={aut.im}>
          <img src="/wp-user/b/landing2.png" />
        </div>
      </div>

      <div className={aut.h1}>
        Welcome to the Best <br />
        <span className={aut.p}>E-banking</span> System
      </div>

      <div className={aut.sub}>
        Our Bank is a safe, fast, easy, and efficient e-Banking system that
        <br />
        enables you access to your bank account and to carry out online banking
        <br />
        services, 24/7
      </div>
      <div>
        <a className={aut.sbtn} href="/wp-user/auth/signup">
          Let’s Get Started
        </a>
      </div>
      <div>
        <a className={aut.h2} href="/wp-user/auth/login">
          Already have an account? &nbsp;
          <span className={aut.p}>Sign In</span>
        </a>
      </div>

      <div className={aut.h3}>Save & Spend with ease</div>

      <div className={aut.h4}>
        We’re determined to help merchants grow their businesses by removing the
        <br />
        barriers to entry that traditional banking have put in their way, including <br /> sky-high transfer fees. These
        blocks are not only unfair but unnecessary.
      </div>

      <Footer2 />
    </div>
  );
}
