import Login, { loginaction } from './index';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import SiteError from '../error';
//import af from './style.module.scss';

function LoginPage() {
  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const loginroutes = {
  path: `login`,
  //loader: mostrloader(apiContext),
  element: <LoginPage />,
  errorElement: <SiteError />,
  children: [
    { index: true, element: <Login />, action: loginaction },
    /*{
      path: 'crypto',
      element: <CryptoDeposit />,
      //loader: contactLoader,
    },*/
  ],
};
