import Card from '../../../../components/card';
import CopyInput from '../../../../components/copyinput';
import Select1 from '../../../../components/select';
import UploadFile from '../../../../components/uploadfile';

import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import up from './style.module.scss';
import { loaderPostFetch } from '../../util';

import { useState, useEffect } from 'react';
import Notif from '../../../../components/notif';

export async function itloader({ params }) {
  const formData = new FormData();
  formData.append('getinstituition', 'getinstituition');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

export default function YourDetailsIndex() {
  const { data,data2, u } = useLoaderData();
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div className={up?.t}>Your Details</div>

      <Card title="Your Details ">
        <Form>
          <CopyInput
            label="Your Username"
            value={u?.username ? u?.username : 'n/a'}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <CopyInput
            label="Your Account Number"
            value={data?.account_no ? data?.account_no : 'n/a'}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <CopyInput
            label="Swift Code"
            value={data2?.swift_code ? data2?.swift_code : ''}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <CopyInput
            label="Routing Number"
            value={data2?.routing_no ? data2?.routing_no : ''}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <CopyInput
            label="Country"
            value={data2?.country ? data2?.country : ''}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />
        </Form>
      </Card>
    </>
  );
}
