import f from './f.module.scss';

import { useState } from 'react';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
export default function Footer() {
  const [y, sY] = useState(new Date().getFullYear());
  return (
    <div className={f.z}>
      <div className={f.b}>
        {' '}
        <div className={f.c}> Copyright © {y}. All rights reserved.</div>
      </div>
    </div>
  );
}
