import ta from './ta.module.scss';

import { useRef, useEffect } from 'react';

export default function TextAr({ placeholder = '', label, value = '', name, rows = '3' }) {
  const y = useRef();
  useEffect(() => {
    if (value) {
      y.current.value = value;
    }
  }, [value]);
  const co = () => {
    console.log('copied');
  };
  return (
    <div>
      <label className={ta.label}>{label}</label>
      <div className={ta.txarea}>
        <textarea placeholder={placeholder} ref={y} rows={rows} name={name}></textarea>
      </div>
    </div>
  );
}
