import AddFundsIndex from './index';
import CryptoDeposit, { afbcloader, afbcaction } from './Crypto';
import YourDetailsIndex, { itloader } from './YourDetails';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import af from './style.module.scss';

function AddFunds() {
  return (
    <div>
      <div className={af.body}>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const afroutes = {
  path: `add-funds`,
  //loader: mostrloader(apiContext),
  element: <AddFunds />,
  children: [
    { index: true, element: <AddFundsIndex /> },
    {
      path: 'crypto',
      element: <CryptoDeposit />,
      loader: afbcloader,
      action: afbcaction,
    },
    {
      path: 'it',
      element: <YourDetailsIndex />,
      loader: itloader,
    },
  ],
};
