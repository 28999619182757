import { Outlet, Link, useLocation, Form } from 'react-router-dom';

import { useEffect, useState } from 'react';
import l2 from './l2.module.scss';

import bi from './icons.module.scss';
import wi from './wicons.module.scss';

export default function Link2({ to, to2, children, icon = 'home', onClick }) {
  function closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  const location = useLocation();
  const [active, sActive] = useState(false);
  const [ka, sKA] = useState(false);
  useEffect(() => {
    let y = window.location.href;

    //http://localhost:3000/dashboard/notifications
    //let k=`http://localhost:3000/dashboard/${to}`;
    let k;
    if (to2) {
      k = `https://${window.location.hostname}/wp-user/${to2}`;
    } else if (to) {
      k = `https://${window.location.hostname}/wp-user/dashboard/${to}`;
    }
    sKA(k);
    //console.log({ y, k });

    if (y == k) {
      sActive(true);
    } else {
      if (y.includes(k) && k != `https://${window.location.hostname}/wp-user/dashboard`) {
        sActive(true);
      } else {
        sActive(false);
      }
    }
  }, [location]);

  return (
    <Link to={`${ka ? ka : to}`}>
      <div
        onClick={() => {
          closeNav();
if(onClick){
          onClick();}
        }}
        className={`${l2.k} ${active ? l2.active : ''}`}
      >
        <div className={`${active ? wi[icon] : bi[icon]}`}></div>

        {children}
      </div>
    </Link>
  );
}
