import sty from './no.module.scss';

export default function Notif({ display = false, type = 'success', msg = '' }) {
  return (
    <div className={`${sty.bg} ${display ? sty.displayN : ''}`}>
      <div className={`${sty.dBox} ${type == 'success' ? sty.success : type == 'error' ? sty.error : sty.warning}`}>
        <div className={`${type == 'success' ? sty.okN : type == 'error' ? sty.cautionN : sty.waitN}`}></div>
        <div className={sty.msg}>{msg}</div>
      </div>
    </div>
  );
}
