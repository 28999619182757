import ci from './ci.module.scss';
import ci2 from './ci2.module.scss';
import { useRef, useEffect } from 'react';
import { csAmount } from '../../pages/dashboard/util';

export default function Input1({ placeholder = '', label, value = '', name, type = 'text', onChange, format = false }) {
  const y = useRef();
  useEffect(() => {
    if (value) {
      y.current.value = value;
    }
  }, [value]);
  const co = () => {
    console.log('copied');
  };
  return (
    <div>
      <label className={ci.label}>{label}</label>
      <div className={ci.c}>
        <input
          ref={y}
          placeholder={placeholder}
          type={format ? 'number' : type}
          onChange={(e) => {
            if (format) {
              y.current.value = csAmount(e.target.value);
            }
            if(onChange){
            onChange(e);}
          }}
          name={name}
        />
      </div>
    </div>
  );
}

export function Input10({
  placeholder = '',
  label,
  value = '',
  name,
  type = 'text',
  onChange,
  format = false,
  potentialValue = '',
}) {
  const y = useRef();
  useEffect(() => {
    if (value) {
      y.current.value = value;
    }
  }, [value]);
  const co = () => {
    console.log('copied');
  };
  return (
    <div>
      <label className={ci2.label}>{label}</label>
      <div className={ci2.c}>
        <input
          ref={y}
          placeholder={placeholder}
          type={format ? 'number' : type}
          onChange={(e) => {
            if (format) {
              y.current.value = csAmount(e.target.value);
            }if(onChange){
            onChange(e);}
          }}
          name={name}
        />
        <div
          className={ci2.trail}
          onClick={() => {
            y.current.value = potentialValue;//csAmount(potentialValue);
          }}
        >
          Max
        </div>
      </div>
    </div>
  );
}
