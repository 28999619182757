import ci from './style.module.scss';
import icons from './icons.module.scss';

import { useRef, useEffect } from 'react';

export default function Select2({ icon, label, value = '', children, name, onChange }) {
  const y = useRef();
  useEffect(() => {
    if (value) {
      y.current.value = value;
    }
  }, [value]);
  const co = () => {
    console.log('copied');
    y.current?.focus();
  };
  return (
    <>
      <label className={ci.label}>{label}</label>
      <div className={ci.c}>
        <div className={icons[icon]}></div>
        <select ref={y} name={name} onChange={onChange}>
          {children}
        </select>
        <div
          className={ci.e}
          onClick={() => {
            co();
          }}
        >
          <div className={ci.icon}></div>
        </div>
      </div>
    </>
  );
}
