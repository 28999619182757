//Dashboard

import { Outlet, Link, useLoaderData, Form, redirect } from 'react-router-dom';

import Link2 from '../../components/link2';
import o from '../icons.module.scss';
import d from './dash.module.scss';
import { afroutes } from './AddFunds/routes';
import DashboardIndex, { itloader } from './index';
import { mproutes } from './MakePay/routes';
import { fdroutes } from './FixedDeposit/routes';
import { nroutes } from './Notifications/routes';

import { statementroutes } from './Statements/routes';
import { contactroutes } from './Contact/routes';
import { sendroutes } from './Send/routes';
import { profileroutes } from './Profile/routes';
import { upgraderoutes } from './Upgrade/routes';

import { settingsroutes } from './Settings/routes';
//import { udetroutes } from './YourDetails/routes';
import { kycroutes } from './Kyc/routes';
import Footer from '../../components/footer';
import SiteError from './errorpage';
import DropDown,{logout} from '../../components/dropdown';

import { loaderPostFetch } from './util';
import { useEffect, useState } from 'react';
//profileroutes

export async function uloader({ params }) {
  const formData = new FormData();
  formData.append('getinstituition', 'getinstituition');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

function Dashboard() {
  //put header, footer and sidebar here
  const { u, notificationcount } = useLoaderData();

  function openNav() {
    document.getElementById('mySidenav').style.width = '250px';
  }

  function closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  const [revDropdown, sRevDropdown] = useState(false);

  return (
    <>
      <div id="mySidenav" className={d.sidenav}>
        {/*<a
          href="javascript:void(0)"
          className={d.closebtn}
          onClick={() => {
            closeNav();
          }}
        >
          &times;
        </a>*/}

        <div className={d.cimg}>
          <img src="/wp-user/b/logo3.png" />
        </div>

        <div className={o.n}>General</div>

        <div className={d.c}>
          <Link2 to2={`dashboard`} icon="home">
            {' '}
            dashboard
          </Link2>

          <Link2 to={`add-funds`} icon="add">
            {' '}
            add funds
          </Link2>
          <Link2 to={`make-payment`} icon="make">
            make payment
          </Link2>
          <Link2 to={`send`} icon="same">
            Bank Transfer{' '}
          </Link2>

          <Link2 to={`#`} icon="loans">
            Loan <div className={o.up}></div>
          </Link2>

          <Link2 to={`fixed-deposit`} icon="fd">
            fixed deposit
          </Link2>
          <Link2 to={`notifications`} icon="not">
            notifications <div className={d.ncdis}>{notificationcount?.nc}</div>
          </Link2>

          <Link2 to={`#`} icon="cc">
            Credit Card <div className={o.up}></div>
          </Link2>

          <Link2 to={`upgrade`} icon="ua">
            Upgrade Account
          </Link2>

          <Link2 to={`contact`} icon="hns">
            Help & Support
          </Link2>

          <Link2 to={`#`} icon="pb">
            Pay Bills <div className={o.up}></div>
          </Link2>

          <Link2 to={`statements`} icon="snr">
            Statements & Report
          </Link2>
          <Link2 to2={`b/legal.pdf`} icon="leg">
            legal
          </Link2>

          <Link2 to={`settings`} icon="set">
            settings
          </Link2>

          {/*<Link2 to={`#`} icon="rv">
            Request Verification
          </Link2>*/}

          <Link2 to={`#`} icon="lo" onClick={()=>{
            logout(); 
          }}>
            Log Out
          </Link2>
        </div>
      </div>
      <div className={d.das}>
        <div className={o.header2}>
          <div className={o.c}>
            <div className={o.mnl}>
              <div
                className={o.menu}
                onClick={() => {
                  openNav();
                }}
              ></div>
              <div className={o.logo}>
                <img src="/wp-user/b/logo3.png" />
              </div>
            </div>

            <div className={o.us}>
              <a
                className={`${notificationcount?.nc == 0 ? o.notifnobell : o.notifi}`}
                href="/wp-user/dashboard/notifications"
              ></a>
              <div
                className={o.img}
                onClick={() => {
                  sRevDropdown(true);
                }}
              >
                {u?.picture ? <img src={`/wp-user/uploads/${u?.picture}`} /> : null}
              </div>
            </div>
          </div>

          {/*
        <span style={{fontSize:'30px',cursor:'pointer'}} onClick={()=>{
          openNav();
        }}  >&#9776; open</span>*/}
        </div>

        <div
          className={d.body}
          onClick={() => {
            closeNav();
            sRevDropdown(false);
          }}
        >
          {revDropdown ? <DropDown value={u} /> : null}
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}
//   { ...udetroutes },
//{ index: true, element: <DashboardIndex /> },
export const dashroutes = {
  path: 'dashboard',
  element: <Dashboard />,
  errorElement: <SiteError />,
  loader: uloader,
  //action: rasaction,
  children: [
    {
      index: true,
      element: <DashboardIndex />,
      loader: itloader,
    },
    { ...settingsroutes },
    { ...kycroutes },
    { ...statementroutes },
    { ...upgraderoutes },
    { ...profileroutes },
    { ...sendroutes },
    { ...contactroutes },
    { ...afroutes },
    { ...nroutes },
    { ...mproutes },
    { ...fdroutes },
  ],
};
