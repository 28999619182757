import sty from './h.module.scss';
import { useNavigate } from 'react-router-dom';

export default function Header2({ href, title }) {
  const navigate = useNavigate();
  return (
    <div className={sty.he}>
      <div className={sty.back} onClick={() => navigate(-1)}></div>
      <a href={href}>{title}</a>
    </div>
  );
}
