import Card from '../../../../components/card';
import CopyInput from '../../../../components/copyinput';
import Input1 from '../../../../components/input';
import Select1 from '../../../../components/select';
import cr from './style.module.scss';

import SubmitBtn from '../../../../components/submit';
import { loaderPostFetch } from '../../util';

import TrsRow from '../../../../components/trs';
import { useState, useEffect } from 'react';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import UploadFile from '../../../../components/uploadfile';
import Notif from '../../../../components/notif';

import { useActionData, useSubmit } from 'react-router-dom';

export async function fdcloader({ params }) {
  const formData = new FormData();
  formData.append('getfdcform', 'getfdcform');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

export async function fdcaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

export default function CryptoDeposit() {
  const { names, addresses,addresses2, transactions, currency, plans } = useLoaderData();
  const [g, sGee] = useState(addresses?.[0]);

  let actionData = useActionData();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon) {
        if (s?.icon == 'success') {
          sN({ ...n, display: true, type: 'warning', msg: 'Payment Pending!' });
        } else {
          sN({ ...n, display: true, type: 'error', msg: 'Something went wrong!' });
        }
      }
      setTimeout(() => {
        sN({ ...n, display: false, type: 'success', msg: 'Copied to clipboard' });
      }, 5000);
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
          setTimeout(() => {
            sN({ display: false, type: 'success', msg: `` }); 
          }, 5000);*/
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div className={cr.h1}>Fixed Deposit</div>
      <div className={cr.h2}>Make Payments </div>

      <Form method="post" encType="multipart/form-data">
        <input type="hidden" name="newfdc" value="newfdc" />

        <Card title="Apply FRD ">
          <div className={cr.h}>Deposit Plan</div>
          <Select1 name="plan"> 
            {plans?.map((n,i)=>{
              return (<option key={i} >{n?.plan_name}</option>);
            })}
          </Select1>
        </Card>

        <Card title="Deposit method Available">
          <div className={cr.h}>
            To deposit, choose the payment method panel and make the payment to the displayed address. After payment has
            been made, come back to fill this form.
          </div>

          <Select1
            label="Type"
            name="type"
            onChange={(e) => {
              sGee(addresses2[e.target.value]);
            }}
          >
            {names?.map((w, i) => {
              return <option key={i} >{w}</option>;
            })}
          </Select1>
          <CopyInput
            label="Address:"
            value={g}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <Input1 name="amount" label="Amount:" />
          <UploadFile label="Payment Proof:" name="fixedDepositPic" />

          <SubmitBtn label="Submit Deposit " />
        </Card>
      </Form>

      {transactions ? <p>Crypto Transactions</p> : null}
      {transactions &&
        transactions?.map((n2, i) => {
          return (
            <TrsRow
              currency={currency}
              key={i}
              type={n2?.type}
              amount={n2?.amount}
              status={n2?.status}
              date={n2?.created_at}
            />
          );
        })}
    </>
  );
}
