import uf from './style.module.scss';

import { useRef, useEffect, useState } from 'react';

export default function UploadFile({ bg = '#eeeef0', label = '', name }) {
  const y = useRef();
  const [fs, sFs] = useState('Click To Upload');
  return (
    <>
      <input
        type="file"
        ref={y}
        hidden={true}
        name={name}
        style={{ display: 'none' }}
        onChange={() => {
          sFs('File Uploaded');
        }}
      />
      <label className={uf.label}>{label}</label>
      <div
        className={uf.c}
        style={{ background: bg, cursor: 'pointer' }}
        onClick={() => {
          y.current.click();
        }}
      >
        <div className={uf.three}>
          <div className={uf.icon}></div>
          <div className={uf.t2}>{fs}</div>
          <div className={uf.t3}>SVG, PNG, JPG or GIF (max. 800x400px)</div>
        </div>
      </div>
    </>
  );
}

export function UploadFile2({ bg = '#eeeef0', label = '', name, icon = 'came' }) {
  const y = useRef();
  const [fs, sFs] = useState('Upload your photo');
  return (
    <>
      <input
        type="file"
        ref={y}
        hidden={true}
        name={name}
        style={{ display: 'none' }}
        onChange={() => {
          sFs('Photo Uploaded');
        }}
      />
      <label className={uf.label}>{label}</label>
      <div
        className={`${uf.c} ${uf.small}`}
        style={{ background: bg, cursor: 'pointer' }}
        onClick={() => {
          y.current.click();
        }}
      >
        <div className={uf.threeV2}>
          <div className={uf[icon]}></div>
          <div className={uf.t2}>{fs}</div>
          {/*
          <div className={uf.t3}>SVG, PNG, JPG or GIF (max. 800x400px)</div>*/}
        </div>
      </div>
    </>
  );
}
