//errorpage.js
import { useEffect } from 'react';
import { Outlet, Link, useLocation, Form, useRouteError } from 'react-router-dom';

export default function SiteError() {
  const location = useLocation();
  const error = useRouteError();
  console.error({ error });
  useEffect(() => {
    let y = window.localStorage.getItem('baUid');
    console.log({ y });
    if (y == null) {
      //window.location.href = `https://${window.location.hostname}/wp-user/auth/login`;
      window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
    } else {
      window.location.href = `https://${window.location.hostname}/wp-user/dashboard`;
    }
  }, [location]);
  //console.log(error)
  return (
    <div>
      {/*
      <p>An Error just occured</p>
       <div>
      An error just occured <i>{error.statusText || error.message}</i>
    </div>*/}
    </div>
  );
}
