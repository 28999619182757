import s from './style.module.scss';

import Input1 from '../../../components/input';
import SubmitBtn3 from '../../../components/submit3';

import TextAr from '../../../components/textarea';

import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import { loaderPostFetch } from '../util';

import { useRef, useState, useEffect } from 'react';
import Notif from '../../../components/notif';

import { useActionData, useSubmit } from 'react-router-dom';

export async function saction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  const password = formData.get('c_pwd');
  const npassword = formData.get('n_pwd');
  const cnpassword = formData.get('con_pwd');
  const errors = {};
  // validate the fields
  if (npassword != cnpassword) {
    errors.failed = 'Password Mismatch';
  } /*
  if (typeof password !== "string" || password.length < 6) {
    errors.password = "Password must be > 6 characters";
  } */
  // return data if we have errors
  if (Object.keys(errors).length) {
    return errors;
  }

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

/*

export async function mpcloader({ params }) {
  const formData = new FormData();
  formData.append('getmpcform', 'getmpcform');
 
  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  } 
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params,...res };
}
 
*/

export async function ploader({ params }) {
  const formData = new FormData();
  formData.append('getprofile', 'getprofile');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const profile = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, profile };
}

export default function SettingsIndex() {
  const { profile } = useLoaderData();

  let actionData = useActionData();

  //disabled
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.failed) {
        sN({ ...n, display: true, type: 'error', msg: s?.failed });
      } else {
        if (s?.status) {
          if (s?.status == 'success') {
            sN({ ...n, display: true, type: 'success', msg: 'Action Successful!' });
          } else {
            sN({ ...n, display: true, type: 'error', msg: 'Something went wrong!' });
          }
        }
      }
      setTimeout(() => {
        sN({ ...n, display: false, type: 'success', msg: 'Copied to clipboard' });
      }, 5000);
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
          setTimeout(() => {
            sN({ display: false, type: 'success', msg: `` }); 
          }, 5000);*/
    }
  }, [actionData]);
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const [form1Disabled, sForm1Disabled] = useState(true);
  const [form2Disabled, sForm2Disabled] = useState(true);

  const [form2, sForm2] = useState({});
  const validateForm2 = () => {};
  useEffect(() => {
    if (Object.keys(form2).length == 3) {
      if (form2?.n_pwd === form2?.con_pwd && form2?.con_pwd?.length > 4) {
        sForm2Disabled(false);
      } else {
        sForm2Disabled(true);
      }
    } else {
      sForm2Disabled(true);
    }
  }, [form2]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div>
        <div className={s.h}>Settings</div>
        <div className={s.sh}>Email</div>
        <div className={s.p1}>
          Your email address is an essential part of your
          <br /> sign in process.
        </div>

        <Form method="post">
          <input type="hidden" name="updateemail" value="updateemail" />

          <Input1
            name="email"
            value={profile?.data?.email}
            label=""
            onChange={(e) => {
              //is valid email
              if (validateEmail(e.target.value)) {
                sForm1Disabled(false);
              } else {
                sForm1Disabled(true);
              }
            }}
          />

          <SubmitBtn3 label="Save " disabled={form1Disabled} />
        </Form>

        <div className={s.sh}>Password</div>
        <div className={s.p1}>
          Edit your sign-in password.
          <br />
        </div>

        <Form method="post">
          <input type="hidden" name="updatepwd" value="updatepwd" />

          <Input1
            name="c_pwd"
            onChange={(e) => {
              sForm2({ ...form2, c_pwd: e.target.value });
            }}
            label="Current Password"
            placeholder="Enter Password"
            type="password"
          />

          <Input1
            name="n_pwd"
            onChange={(e) => {
              sForm2({ ...form2, n_pwd: e.target.value });
            }}
            label="Enter new password"
            placeholder="Enter new password"
            type="password"
          />

          <Input1
            name="con_pwd"
            onChange={(e) => {
              sForm2({ ...form2, con_pwd: e.target.value });
            }}
            label="Confirm new password"
            placeholder="Retype new password"
            type="password"
          />

          <SubmitBtn3 label="Save " disabled={form2Disabled} />
        </Form>
      </div>
    </>
  );
}
