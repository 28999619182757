import { loginroutes } from './login/routes';
import { forgotroutes } from './forgot/routes';
import { resetroutes } from './reset/routes';
import { signuproutes } from './signup/routes';
import { veroutes } from './verifyemail/routes';
import { varoutes } from './verifyaccount/routes';
import SiteIndex from './index';

import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import aut from './auth.module.scss';

import SiteError from './error';

function Auth() {
  return (
    <>
      <div className={aut.auht}>
        <div className={aut.body}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
export const authroutes = {
  path: 'auth',
  element: <Auth />,
  errorElement: <SiteError />,
  //loader: rasloader,
  //action: rasaction,
  children: [
    {
      index: true,
      element: <SiteIndex />,
      //loader: itloader,
    },
    { ...loginroutes },
    { ...varoutes },
    { ...veroutes },
    { ...signuproutes },
    { ...forgotroutes },
    { ...resetroutes },
  ],
};

/*
  {index: true,  
      element: <DashboardIndex />,
      //loader: contactLoader,
    },*/
