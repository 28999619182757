import Forgot, { forgotaction } from './index';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

//import af from './style.module.scss';

function ForgotPage() {
  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const forgotroutes = {
  path: `forgot`,
  //loader: mostrloader(apiContext),
  element: <ForgotPage />,
  children: [
    { index: true, element: <Forgot />, action: forgotaction },
    /*{
      path: 'crypto',
      element: <CryptoDeposit />,
      //loader: contactLoader,
    },*/
  ],
};
