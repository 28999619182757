import r1 from './row1.module.scss';
import i from './icons.module.scss';

import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
export default function Row1({ h, s, d }) {
  return (
    <Link to={`${d?.href ? d?.href : '#'}`}>
      <div className={r1.b}>
        <div className={r1.l}>
          <div className={i?.[d?.icon]}></div>

          <div className={r1.details}>
            <div className={r1.h}>{h}</div>
            <div className={r1.s}>{s}</div>
          </div>
        </div>
        <div className={r1.r}>
          <div className={r1.tx}>{d?.availabilty != '' ? d?.availabilty : null}</div>

          <div className={r1.icon}></div>
        </div>
      </div>
    </Link>
  );
}
