import Card from '../../../components/card';
import CopyInput from '../../../components/copyinput';
import Select1 from '../../../components/select';
import UploadFile from '../../../components/uploadfile';

import Input1 from '../../../components/input';
import SubmitBtn from '../../../components/submit';

import TrsRow from '../../../components/trs';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import up from './style.module.scss';

import Notif from '../../../components/notif';

import { useActionData, useSubmit } from 'react-router-dom';
import { loaderPostFetch } from '../util';

import { useState, useEffect } from 'react';

export async function upgradeloader({ params }) {
  const formData = new FormData();
  formData.append('getupgradeform', 'getupgradeform');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

export async function upgradeaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

export default function UpgradeIndex() {
  const { names, addresses,addresses2, purposes, transactions, currency, plans } = useLoaderData();
  const [g, sGee] = useState(addresses?.[0]);

  let actionData = useActionData();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon) {
        if (s?.icon == 'success') {
          sN({ ...n, display: true, type: 'warning', msg: 'Payment Pending!' });
        } else {
          sN({ ...n, display: true, type: 'error', msg: 'Something went wrong!' });
        }
      }
      setTimeout(() => {
        sN({ ...n, display: false, type: 'success', msg: 'Copied to clipboard' });
      }, 5000);
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
          setTimeout(() => {
            sN({ display: false, type: 'success', msg: `` }); 
          }, 5000);*/
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div className={up?.t}>Upgrade Account</div>
      <div className={up.s}>Upgrade account to be able to access more features and increased limit</div>
      <Form method="post" encType="multipart/form-data">
        <input type="hidden" name="newupgrade" value="newupgrade" />
        <Card title="Upgrade ">
          <Select1 name="plan" required={true} >
            <option>Select ..</option>
            {plans?.map((n,i)=>{
              return (<option key={i}>{n?.plan_name}</option>);
            })}
          </Select1>
        </Card>
        <Card title="Deposit method Available ">
          <div className={up.s2}>
            To deposit, choose the payment method panel and make the payment to the displayed address. After payment has
            been made, come back to fill this form.
          </div>

          <Select1
            label="Type"
            name="type"
            onChange={(e) => {
              sGee(addresses2[e.target.value]);
            }}
          >
            {/*
            <option>Bitcoin</option>*/}
            {names?.map((w, i) => {
              return <option key={i} >{w}</option>;
            })}
          </Select1>
          <CopyInput
            label="Address:"
            value={g}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <Input1 name="amount" label="Amount:" />

          <UploadFile label="payment proof" name="depositPic" />

          <SubmitBtn label="Submit Transfer " />
        </Card>
      </Form>

      {transactions ? <p>Crypto Transactions</p> : null}
      {transactions &&
        transactions?.map((n, i) => {
          return (
            <TrsRow
              currency={currency}
              key={i}
              type={n?.type}
              amount={n?.amount}
              status={n?.status}
              date={n?.created_at}
            />
          );
        })}
    </>
  );
}
