import s from './style.module.scss';

import { Link, useLoaderData, Form } from 'react-router-dom';
import { loaderPostFetch } from '../util';

export async function cloader({ params }) {
  const formData = new FormData();
  formData.append('getcontact', 'getcontact');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

function WbSection({ icon, t, s2 }) {
  return (
    <div className={s.wbs}>
      <div className={s[icon]}></div>
      <div className={s.res}>
        <div className={s.t}>{t}</div>
        <div className={s.s}>{s2}</div>
      </div>
    </div>
  );
}

function WnSection({ icon, t, val }) {
  return (
    <div className={s.wns}>
      <div className={s.asu}>{t}</div>
      <div className={s.con}>
        <div className={s.val}>{val}</div>
        <div className={s.btn}>
          <div className={s[icon]}> </div>
          <div className={s.btnt}>{t}</div>
        </div>
      </div>
    </div>
  );
}

export default function Contact() {
  const { ct } = useLoaderData();
  return (
    <div>
      <div className={s.h}>Contact Page</div>
      <div className={s.su}>Chat</div>

      <div className={s.wb}>
        <WbSection icon="lws" t="Live Web Chat " s2="Start a conversation on live chat" />
        <WbSection icon="wa" t="Whatsapp" s2="Start a conversation on whatsapp" />
      </div>
      {ct?.email ? <WnSection icon="email" t="Email" val={ct?.email} /> : null}

      {ct?.phone ? <WnSection icon="phone" t="Call" val={ct?.phone} /> : null}
    </div>
  );
}
