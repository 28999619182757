import sn from './sn.module.scss';

import {useState} from 'react';

export default function SendNotice({display,t,b}){

	const [d,sD]=useState(display);

	return (
    <div className={`${sn.bg} ${d ? sn.displayN : ''}`}>
      <div className={sn.con}>

		<div className={sn.h}>{t}
		</div>

		<div className={sn.b}>{b}
		</div>

		<div className={sn.okay} onClick={()=>{
			sD(false);
			window.location.href = `../`;
		}}>Okay
		</div>

		</div>
		</div>
		);
}