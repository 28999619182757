import VerifyEmail, { veaction, veloader } from './index';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

//import af from './style.module.scss';

function VerifyEmailPage() {
  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const veroutes = {
  path: `verify`,
  //loader: mostrloader(apiContext),
  element: <VerifyEmailPage />,
  children: [
    /*{ index: true, element: <VerifyEmail />, action: veaction },*/
    {
      path: ':email',
      element: <VerifyEmail />,
      action: veaction,
      loader: veloader,
    },
  ],
};
