import { Link, useLoaderData, Form } from 'react-router-dom';

import af from './style.module.scss';
import Row1 from '../../../components/row1';

export const afloader = () => {
  /*
   */
};

export default function MakePaymentIndex() {
  const y = [
    {
      name: 'Bank Transfer',
      icon: 'bank',
      sub: 'From bank app or internet banking.',
      availabilty: 'Not available for your region',
    },
    {
      name: 'card',
      icon: 'card',
      sub: 'Add money with a debit/credit card',
      availabilty: 'Not available for your region',
    },
    {
      name: 'cash deposit',
      icon: 'cash',
      sub: 'Deposit cash from your local bank',
      availabilty: 'Not available for your region',
    },
    {
      href: 'crypto',
      name: 'Crypto Deposit',
      icon: 'crypto',
      sub: 'Make paymets swiftly with crypto currency ',
      availabilty: '',
    },
  ];

  return (
    <div>
      <div className={af.body}>
        <div className={af.u}>Make Payments</div>

        {y?.map((n, i) => {
          return (
            <div key={i}>
              <Row1 h={n?.name} s={n?.sub} d={n} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
