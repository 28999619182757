import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

//import Home from './pages/root';
import { dashroutes } from './pages/dashboard/routes';
import { authroutes } from './pages/auth/routes';

const AppRoot = () => {
  let y = window.localStorage.getItem('baUid');
  let x = window.localStorage.getItem('account_verification');
  let i = { basename: '/wp-user' };
  //i = {};
  if (y == null) {
    const k4dUrouter = createBrowserRouter([{ ...authroutes }], i);
    return <RouterProvider router={k4dUrouter} />;
    /*} else if(x!=null) {
    if (x) {

      const k4dUrouter = createBrowserRouter([{ ...authroutes }], i);
    return <RouterProvider router={k4dUrouter} />;
  
    }*/
  } else {
    //, { ...authroutes }
    const k4dUrouter = createBrowserRouter([{ ...dashroutes }], i);
    return <RouterProvider router={k4dUrouter} />;
  }
};

/*
export default function HofA() {
  return (
    <>
      {' '}
      <LoaderProvider>
        <AppRoot />
        <ToastContainer />
      </LoaderProvider>
    </>
  );
}*/

export default function Bank1() {
  return (
    <>
      <AppRoot />
    </>
  );
}
