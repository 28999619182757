import s from './style.module.scss';
import { Link, useLoaderData, Form } from 'react-router-dom';

import { loaderPostFetch, csAmount } from '../util';

export async function sloader({ params }) {
  const formData = new FormData();
  formData.append('getstatement', 'getstatement');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

export function Entry({ data, currency }) {
  function h(n) {
    let y = n.replace('_', ' ');
    return y;
  }
  return (
    <>
      {/*JSON.stringify(data)*/}
      <div className={s.entry}>
        <div className={s.lef}>
          <div className={s[data?.transaction_type]}></div>
          <div>
            <div className={s.t1}>
              {/*Transfer {data?.transaction_type == 'credit' ? 'from' : 'to'*/} <b>{data?.name}</b>
            </div>
            <div className={s.t2}>{new Date(data?.date).toDateString()}</div>
          </div>
        </div>
        <div className={s.rig}>
          <div className={s.amount}>
            {data?.transaction_type == 'credit' ? `+${currency}` : `-${currency}`}
            {data?.amount ? csAmount(data?.amount) : '0.00'}
          </div>

          <div className={s[data?.status]}>{h(data?.status)}</div>
        </div>
      </div>
    </>
  );
}

const v = {
  transaction_type: 'credit',
  status: 'failed',
  date: new Date().getTime(),
  name: 'Paris',
  amount: '-$58,685.40',
};

const v2 = {
  transaction_type: 'credit',
  status: 'successful',
  date: new Date().getTime(),
  name: 'Paris',
  amount: '-$58,685.40',
};

const v3 = {
  transaction_type: 'credit',
  status: 'in_progress',
  date: new Date().getTime(),
  name: 'Paris',
  amount: '-$58,685.40',
};
export default function StatementIndex() {
  const { transactions, currency } = useLoaderData();
  return (
    <div>
      <div className={s.h}>Statements & Report</div>
      <div className={s.s}>Transactions</div>

      {!transactions || transactions?.length == 0 ? (
        <div className={s.empty}>
          <div className={s.c}>
            <div className={s.h}>No Transaction History</div>
            <div className={s.s}>
              Your recent transaction should appear here contact
              <br /> support for any issues
            </div>
          </div>
        </div>
      ) : null}
      {transactions &&
        transactions?.map((n, i) => {
          return <Entry data={n} key={i} currency={currency} />;
        })}

      {/*<Entry data={v} />
      <Entry data={v2} />
      <Entry data={v3} />*/}
    </div>
  );
}
