import Reset, { resetaction, resetloader } from './index';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

//import af from './style.module.scss';

function ResetPage() {
  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const resetroutes = {
  path: `reset`,
  //loader: mostrloader(apiContext),
  element: <ResetPage />,
  children: [
    {
      path: 'r/:email/:token',
      element: <Reset />,
      action: resetaction,
      loader: resetloader,
    },
    { index: true, element: <Reset /> ,
      action: resetaction,
      loader: resetloader,},
    /*{
      path: 'crypto',
      element: <CryptoDeposit />,
      //loader: contactLoader,
    },*/
  ],
};
