import { Outlet, Link, useLocation, Form } from 'react-router-dom';
import { useEffect } from 'react';

export default function SiteError() {
  const location = useLocation();
  useEffect(() => {
    let y = window.localStorage.getItem('baUid');
    console.log({ y });
    if (y != null) {
      window.location.href = `https://${window.location.hostname}/wp-user/dashboard`;
    } else {
      window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
      //window.location.href = `https://${window.location.hostname}/wp-user/auth/login`;
    }
  }, [location]);
  return (
    <div>
      {/*
      <p>An Error just occured</p>
    */}
    </div>
  );
}
