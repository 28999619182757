import c from './card.module.scss';

export default function Card({ title, children }) {
  return (
    <div className={c.card}>
      <div className={c.top}>{title}</div>
      <div className={c.b}>{children}</div>
    </div>
  );
}

export function Card2({ children }) {
  return (
    <div className={c.card}>
      <div className={c.b}>{children}</div>
    </div>
  );
}
