import MakePaymentIndex from './index';
import CryptoDeposit, { fdcloader, fdcaction } from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import af from './style.module.scss';

function MakePayment() {
  return (
    <div>
      <div className={af.body}>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const fdroutes = {
  path: `fixed-deposit`,
  //loader: mostrloader(apiContext),
  element: <MakePayment />,
  children: [
    { index: true, element: <MakePaymentIndex /> },
    {
      path: 'crypto',
      element: <CryptoDeposit />,
      loader: fdcloader,
      action: fdcaction,
    },
  ],
};
