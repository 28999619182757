import Input2 from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';
import Header2 from '../../../components/header2';
import Notif from '../../../components/notif';

import Footer2 from '../../../components/footer2';
import { loaderPostFetch } from '../util';
import { useEffect, useState } from 'react';

import { Outlet, Link, useLoaderData, useActionData, Form } from 'react-router-dom';

export async function loginaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  //updates['']
  //let contact;

  const contact = await loaderPostFetch(`api/users.php?crud=userlogin`, formData);

  return contact;
}

/*
export async function ssloader() {

  const sessions = await getfetch(`${apiUrl}/sessionlist`, {}); 
  return { sessions };
}*/

export default function Login() {
  let actionData = useActionData();
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.error) {
        //s?.message

        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });

          if (!s?.verified) {
            window.localStorage.setItem('notverified', true);
            if (s?.email) {
              window.location.href = `verify/${s?.email}`;
            }
          } else if (s?.verification_status != 'Not Verified') {
            window.localStorage.setItem('account_verification', false);

            if (s?.submitted == 'No') {
              window.location.href = `account/verification`;
            } else {
              window.location.href = `account/under-review`;
            }
          } else {
          }
        }, 5000);
      } else {
        if (s?.role == 'admin') {
          sN({ display: true, type: 'success', msg: `${s?.message}` });
          setTimeout(() => {
            sN({ display: false, type: 'success', msg: `` });
            window.location.href = '../../wp-admin';
          }, 5000);
        } else {
          /*users*/
          if (s?.verified && !s?.verified) {
            //console.log("usn");
            window.localStorage.setItem('tempbaUid', s?.jwt);

            window.localStorage.setItem('notverified', true);
            if (s?.email) {
              window.location.href = `verify/${s?.email}`;
            }
          } else if (s?.verification_status == 'Not Verified' || s?.verification_status == 'Declined') {
            window.localStorage.setItem('account_verification', false);
            window.localStorage.setItem('tempbaUid', s?.jwt);

            window.location.href = `account/verification`;
          } else if (s?.verification_status == 'Pending Verification') {
            window.localStorage.setItem('account_verification', false);
            window.localStorage.setItem('tempbaUid', s?.jwt);

            window.location.href = `account/under-review`;
          } else {
            window.localStorage.setItem('baUid', s?.jwt);
            //window.location.reload();
            sN({ display: true, type: 'success', msg: `${s?.message}` });
            setTimeout(() => {
              sN({ display: false, type: 'success', msg: `` });
              window.location.href = '../dashboard';
            }, 5000);
          }
        }
      }
    }
  }, [actionData]);

  return (
    <div>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />
      <Header2 href="signup" title="Sign Up" />

      <div className={sty.logo}>
        <img src="/wp-user/b/logo.png" />
      </div>

      <div className={sty.sub}>
        <div className={sty.text}>Login Account</div>

        <div className={sty.fingerprint}></div>
      </div>

      <div className={sty.h}>It’s good to have you back!</div>
      <div className={sty.s}>
        Enter your email and password and get the help you need, without bureaucracy or difficulties, and the best
        thing, it's all free!
      </div>

      <Form method="post">
        <Input2 placeholder="Enter mail" hasIcon="email" name="email" required={true} />
        <Input2 placeholder="Enter Password" name="password" type="password" hasIcon="show" toggleIconWith="hide" />

        <div className={sty.remeber}>
          <div className={sty.l}>
            <input type="checkbox" />
            <div className={sty.text}>Remember me</div>
          </div>
          <div className={sty.r}>
            <a href="forgot">Forgot Password?</a>
          </div>
        </div>

        <SubmitBtn2 label="Continue" />
      </Form>

      <Footer2 />
    </div>
  );
}
