import VerifyAccount, { vaaction, valoader } from './index';
import UnderReview, { uraction, urloader } from './underreview';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

//import af from './style.module.scss';

function VerifyAccountPage() {
  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const varoutes = {
  path: `account`,
  //loader: mostrloader(apiContext),
  element: <VerifyAccountPage />,
  children: [
    { index: true, element: <VerifyAccount />, action: vaaction, loader: valoader },
    {
      path: 'verification',
      element: <VerifyAccount />,
      action: vaaction,
      loader: valoader,
    },
    {
      path: 'under-review',
      element: <UnderReview />,
      action: vaaction,
      loader: valoader,
    },
  ],
};
