import Signup, { signupaction } from './index';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

//import af from './style.module.scss';

function SignupPage() {
  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const signuproutes = {
  path: `signup`,
  //loader: mostrloader(apiContext),
  element: <SignupPage />,
  children: [
    { index: true, element: <Signup />, action: signupaction },
    /*{
      path: 'crypto',
      element: <CryptoDeposit />,
      //loader: contactLoader,
    },*/
  ],
};
