import Input2 from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';
import Header2 from '../../../components/header2';

import Notif from '../../../components/notif';
import { useState,useEffect } from 'react';
import Footer2 from '../../../components/footer2';
import { Outlet, Link, useLoaderData,useActionData, Form } from 'react-router-dom';

import { loaderPostFetch } from '../util';

export async function forgotaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();
  formData.append("action","code");
  const updates = Object.fromEntries(formData);
  //updates['action'] = 'code';
  //let contact;
  //const contact = await
  const contact = await loaderPostFetch(`api/users.php?crud=userforgot`, formData);/*
    .then((s) => {
      if (s?.error) {
        //s?.message
      } else {
      }
    })
    .catch((e) => {
      console.log(e);
    });*/

  return {...contact,...params,...updates};
}

export default function Forgot() {
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  const [em, sEm] = useState();
  let actionData = useActionData();


  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon == 'success') {
        window.localStorage.setItem('email', s?.email);
        //window.localStorage.removeItem('tempbaUid');

        sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
          window.location.href = `../reset/r/${s?.email}/1`;
          //window.location.href = '../';
        }, 5000);
      }else if (s?.icon=="error") {
        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      } 
    }
  }, [actionData]);

  const resendCode = (email) => {
    //fetch()
    let formData = new FormData();
    if (email) {
      formData.append('email', email);
      formData.append('resendforgotcode', 'resendforgotcode');

      sN({ display: true, type: 'success0', msg: `Please Wait` });

      loaderPostFetch(`api/users.php`, formData)
        .then((d) => {
          sN({ display: true, type: 'success', msg: `Code Sent` });
          setTimeout(() => {
            sN({ display: false, type: 'error', msg: `` });
          }, 5000);
        })
        .catch((e) => {
          sN({ display: true, type: 'error', msg: `Something went wrong` });

          setTimeout(() => {
            sN({ display: false, type: 'error', msg: `` });
          }, 5000);
        });
    }
  };

  return (
    <div>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />
      <Header2 href="login" title="Sign In" />

      <div className={sty.sub}>
        <div className={sty.text}>Forgot Password?</div>

        <div className={sty.lock}></div>
      </div>

      <div className={sty.s}>
        Enter the email address with your account and we'll send an eamil with confirmation to reset your password.
      </div>
      <Form method="post">
        <Input2
          type="email"
          name="email"
          placeholder="Email Address"
          onChange={(e) => {
            sEm(e.target.value);
          }}
        />

        {/*<div className={sty.remeber}>
		<div className={sty.l}>
		<input type="checkbox" />
		<div className={sty.text}>I&apos;m at least 18 years old and agree to the following terms:</div>
		</div>
		<div className={sty.r}>
		<a href="">Forgot Password?</a>
		</div>
		</div>

		<div className={sty.note}>
By tapping Next. I've read and agree to the  
		<div className={sty.imp}>E-Sign Disclosure and Consent 
		</div>to receive all communications  electronically

		</div>*/}

        <SubmitBtn2 label="Send reset link" />
      </Form>

      <div
        className={sty.resend}
        onClick={() => {
          resendCode(em);
        }}
      >
        Haven’t gotten the mail yet? <a>Resend</a>
      </div>

      <Footer2 />
    </div>
  );
}
