import s from './style.module.scss';
import Card, { Card2 } from '../../../components/card';
import Input1 from '../../../components/input';
import Notif from '../../../components/notif';

import CopyInput from '../../../components/copyinput';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import { useRef, useState, useEffect } from 'react';
import SubmitBtn from '../../../components/submit';
import { loaderPostFetch } from '../util';

import { useActionData, useSubmit } from 'react-router-dom';

export async function paction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

export async function ploader({ params }) {
  const formData = new FormData();
  formData.append('getprofile', 'getprofile');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const profile = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, profile };
}

export default function ProfileIndex() {
  const { profile } = useLoaderData();
  const picForm = useRef();
  const picInput = useRef();
  let submit = useSubmit();
  let actionData = useActionData();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.status) {
        if (s?.status) {
          sN({ ...n, display: true, type: 'success', msg: 'Action Successful' });
        } else {
          sN({ ...n, display: true, type: 'error', msg: 'Something went wrong!' });
        }
      }
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
       */
      setTimeout(() => {
        sN({ display: false, type: 'success', msg: `` });
      }, 5000);
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div>
        <div className={s.h}>Profile</div>

        <Form
          encType="multipart/form-data"
          ref={picForm}
          method="post"
          onChange={(event) => {
            submit(event.currentTarget);
          }}
        >
          <input type="file" ref={picInput} name="profilePic" hidden={true} />
          <input type="hidden" name="updatepic" value="updatepic" />
          <Card title="About ">
            <div className={s.profcon}>
              <div className={s.profduo}>
                <div className={s.i}>
                  <img src={`/wp-user/uploads/${profile?.data?.picture}`} />
                </div>
                <div className={s.list}>
                  <div className={s.li1}>{profile?.data?.fullname}</div>
                  <div className={s.li2}>{profile?.data?.email}</div>
                </div>
              </div>

              <div
                className={s.btn}
                onClick={() => {
                  picInput.current.click();
                  console.log('say');
                }}
              >
                Update Pic
              </div>
            </div>
          </Card>
        </Form>

        <Card title="About ">
          <CopyInput
            label="Your Username"
            value={`@${profile?.data?.username}`}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />

          <CopyInput
            label="Your Account Number"
            value={profile?.it?.account_no}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />
        </Card>

        <Form method="post">
          <input type="hidden" name="updateprofile" value="updateprofile" />

          <Card2>
            <Input1 name="fullname" value={profile?.data?.fullname} label="Fullname" />

            <div className={s.duo}>
              <Input1 name="phone" value={profile?.data?.phone} label="Number" />

              <Input1 name="gender" value={profile?.data?.gender} label="Gender" />
            </div>

            <div className={s.duo}>
              <Input1 name="dob" value={profile?.data?.dob} label="Date Of Birth" />

              <Input1 name="country" value={profile?.data?.country} label="Country " />
            </div>

            <Input1 value={profile?.data?.marital_status} name="marital_status" label="Marital Status" />

            <Input1 value={profile?.data?.home_address} name="home_address" label="Personal Address" />

            <Input1 name="next_of_kin" label="Next Of Kin" />

            <SubmitBtn label="Update Profile" />
          </Card2>
        </Form>
      </div>
    </>
  );
}
