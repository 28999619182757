import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import './index.css';
//import App from './App'; 

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
 

import Bank1 from './bank1'; 
const root = ReactDOM.createRoot(document.getElementById('root'));
 

root.render(<Bank1 />);  