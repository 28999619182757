import NotificationsIndex, { nloader,naction } from './index';
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import af from './style.module.scss';

function No() {
  return (
    <div>
      <div className={af.body}>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const nroutes = {
  path: `notifications`,
  //loader: mostrloader(apiContext),
  element: <No />,
  children: [
    { index: true, element: <NotificationsIndex />, loader: nloader, action:naction },
    /*{
      path: 'crypto',
      element: <CryptoDeposit />,
      //loader: contactLoader,
    },*/
  ],
};
