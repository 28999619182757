import SendIndex, { saction, sloader } from './index';
import Confirm, { caction, cloader } from './confirm';
//
//import CryptoDeposit from './Crypto';
//import Crypto from './Crypto';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

import af from './style.module.scss';

function Send() {
  return (
    <div>
      <div className={af.body}>
        <Outlet />
      </div>
    </div>
  );
}
//    { index: true, element: <AddFundsIndex /> },

export const sendroutes = {
  path: `send`,
  //loader: mostrloader(apiContext),
  element: <Send />,
  children: [
    { index: true, element: <SendIndex />, action: saction, loader: sloader },
    {
      path: 'confirm',
      element: <Confirm />,
      action: caction,
      loader: cloader,
    },
  ],
};
