import Card from '../../../../components/card';
import CopyInput from '../../../../components/copyinput';
import Input1 from '../../../../components/input';
import Select1 from '../../../../components/select';
import cr from './style.module.scss';

import SubmitBtn from '../../../../components/submit';
import { loaderPostFetch } from '../../util';

import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import UploadFile from '../../../../components/uploadfile';

import { useState, useEffect } from 'react';
import Notif from '../../../../components/notif';

import TrsRow from '../../../../components/trs';

import { useActionData, useSubmit } from 'react-router-dom';

export async function afbcloader({ params }) {
  const formData = new FormData();
  formData.append('getcdform', 'getcdform');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

export async function afbcaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

export default function CryptoDeposit() {
  const { names, addresses,addresses2, transactions, currency } = useLoaderData();
  const [g, sGee] = useState(addresses?.[0]);

  let actionData = useActionData();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon) {
        if (s?.icon == 'success') {
          sN({ ...n, display: true, type: 'warning', msg: 'Payment Pending!' });
        } else {
          sN({ ...n, display: true, type: 'error', msg: 'Something went wrong!' });
        }
      }
      setTimeout(() => {
        sN({ ...n, display: false, type: 'success', msg: 'Copied to clipboard' });
      }, 5000);
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
          setTimeout(() => {
            sN({ display: false, type: 'success', msg: `` }); 
          }, 5000);*/
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div className={cr.h2}>Crypto Deposit</div>
      <div className={cr.p2}>Deposit into your trading accounts</div>

      <div className={cr.h3}>Methods</div>
      <Card title="Deposit method">
        <div className={cr.h}>
          To deposit, choose the payment method panel and make the payment to the displayed address. After payment has
          been made, come back to fill this form.
        </div>

        <Form method="post" encType="multipart/form-data">
          <input type="hidden" name="newcd" value="newcd" />

          <Select1
            label="Type"
            name="type"
            onChange={(e) => {
              sGee(addresses2[e.target.value]);
            }}
          >
            {/*
            <option>Bitcoin</option>*/}
            {names?.map((w, i) => {
              return <option key={i} >{w}</option>;
            })}
          </Select1>
          <CopyInput
            label="Address:"
            value={g}
            proceed={() => {
              sN({ display: true, type: 'success', msg: `Copied to clipboard` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
              }, 5000);
            }}
          />
          <Input1 name="amount" type="number" label="Amount:" />
          <UploadFile label="Payment Proof:" name="depositPic" />

          <SubmitBtn label="Submit Deposit " />
        </Form>
      </Card>

      {transactions?.length > 0 ? (
        <>
          <p>Crypto Transactions</p>

          {transactions?.map((no, i) => {
            return (
              <TrsRow
                key={i}
                currency={currency}
                type={no?.type}
                amount={no?.amount}
                status={no?.status}
                date={no?.created_at}
              />
            );
          })}
        </>
      ) : null}
    </>
  );
}
