import Input2 from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';
import Header2 from '../../../components/header2';

import Notif from '../../../components/notif';
import { useState,useEffect } from 'react';
import { Outlet, Link, useLoaderData,useActionData, Form } from 'react-router-dom';

import { loaderPostFetch } from '../util';

export async function resetaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();
  formData.append("action","reset");
  const updates = Object.fromEntries(formData);
  updates['action'] = 'reset';
  //let contact;
  //const contact = await
  const contact = await loaderPostFetch(`api/users.php?crud=userforgot`, formData);/*
    .then((s) => {
      if (s?.error) {
        //s?.message
      } else {
      }
    })
    .catch((e) => {
      console.log(e);
    });*/

  return contact;
}
export async function resetloader({ params }) {
  console.log({ params });

  const sessions = {}; //new Promise();// await getfetch(`${apiUrl}/sessionlist`, {});
  return { sessions, ...params };
}

export default function Reset() {
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  //requires params email/:token
  //const email = '';
  let actionData = useActionData();
  const { email,token } = useLoaderData();


  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon == 'success') {
        //window.localStorage.setItem('baUid', s?.jwt);
       // window.localStorage.removeItem('tempbaUid');
       sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
          window.location.href = '../';
        }, 5000);

        
      }else if (s?.icon=="error") {
        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      } 
    }
  }, [actionData]);

  //
  return (
    <div>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />
      <Header2 href="login" title="Sign In" />

      <div className={sty.sub}>
        <div className={sty.text}>Create New Password</div>

        <div className={sty.lock}></div>
      </div>

      <div className={sty.s}>This password should be diffrent from your previous password</div>

      <Form method="post">
      <Input2 name="reset_code" type="password" placeholder="Reset Code" hasIcon="show" toggleIconWith="hide" />
        
        {/*<input type="hidden" name="reset_code" value={token} />*/}
        <input type="hidden" name="email" value={email} />
        <Input2 name="new_password" type="password" placeholder="Password" hasIcon="show" toggleIconWith="hide" />
        <Input2 type="password" placeholder="Confirm Password" hasIcon="show" toggleIconWith="hide" />

        <div className={sty.remeber}>
          <div className={sty.l}>
            <input type="checkbox" />
            <div className={sty.text}>I&apos;m at least 18 years old and agree to the following terms:</div>
          </div>
          {/*<div className={sty.r}>
		<a href="">Forgot Password?</a>
		</div>*/}
        </div>

        <div className={sty.note}>
          By tapping Next. I've read and agree to the
          <a className={sty.imp} href="/b/sign.pdf">
            E-Sign Disclosure and Consent
          </a>{' '}
          to receive all communications electronically
        </div>

        <SubmitBtn2 label="Continue" />
      </Form>
    </div>
  );
}
