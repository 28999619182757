import s from './index.module.scss';
import i2 from './i2.module.scss';
import { loaderPostFetch, csAmount } from './util';

import Notif from '../../components/notif';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import { Entry } from './Statements';
import { useState, useEffect } from 'react';

export async function itloader({ params }) {
  const formData = new FormData();
  formData.append('getinstituition', 'getinstituition');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }

  const res = await loaderPostFetch(`api/users.php`, formData);

  const formData2 = new FormData();
  formData2.append('getstatement2', 'getstatement2');
  if (tok != null) {
    formData2.append('token', tok);
  }
  const res2 = await loaderPostFetch(`api/users.php`, formData2);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res2, ...res };
}

export default function DashboardIndex() {
  //list ttransactions

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  const { data, u, transactions, currency, tb } = useLoaderData();

  const [disAmount, sDisAmount] = useState();
  useEffect(() => {
    let y = window.localStorage.getItem('displayAmount');
    sDisAmount(y);
  }, []);
  useEffect(() => {
    if (disAmount) {
      window.localStorage.setItem('displayAmount', disAmount);
    }
  }, [disAmount]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div>
        <div className={i2.b2}>
          <div className={i2.bc1}>
            <div className={i2.fullname}>
              <div>{u.fullname} </div>
              <div className={i2.icon}>
                <div
                  className={`${disAmount ? i2.hide : i2.show}`}
                  onClick={() => {
                    let u = !disAmount;
                    sDisAmount(u);
                  }}
                ></div>
              </div>
            </div>
            <div className={i2.balance}>
              {disAmount ? (
                <>{`${currency}${tb?.current_balance ? csAmount(tb?.current_balance) : '0.00'}`}</>
              ) : (
                '*******'
              )}
            </div>
            <div className={i2.accty}>Savings Account</div>

            <div className={i2.an}>
              <div className={i2.l}>
                <div className={i2.a}> Account Number &nbsp;</div>
                <div className={i2.b}> :&nbsp;</div>
                <div className={i2.b}> &nbsp;&nbsp;{data?.account_no}</div>
              </div>
              <div
                className={i2.r}
                onClick={() => {
                  navigator.clipboard.writeText(data?.account_no).then(
                    () => {
                      console.log('s');
                      /* clipboard successfully set */
                      sN({ ...n, display: true, type: 'success', msg: 'Copied to clipboard' });
                      setTimeout(() => {
                        sN({ ...n, display: false, type: 'success', msg: 'Copied to clipboard' });
                      }, 5000);
                    },
                    () => {
                      console.log('f');
                      /* clipboard write failed */
                    },
                  );
                }}
              ></div>
            </div>
          </div>
          <div className={i2.bc2}></div>
        </div>

        {/*<div className={s.box}>
        <div className={s.box1}>
          <div className={s.h}> Current Balance</div>
          <div className={s.h2}> $57,569.00</div>
          <div className={s.h3}>
            {' '}
            Account Number:
            <div className={s.b}>
              {' '}
              <b>2716273849</b>
            </div>
            <div className={s.copy}></div>
          </div>
        </div>
        <div className={s.box2}>
          <div className={s.xx}></div>
        </div>
      </div>*/}

        {!transactions || transactions?.length == 0 ? (
          <div className={s.empty}>
            <div className={s.c}>
              <div className={s.h}>No Transaction History</div>
              <div className={s.s}>
                Your recent transaction should appear here contact
                <br /> support for any issues
              </div>
            </div>
          </div>
        ) : (
          <>
            {transactions ? (
              <>
                <div className={s.s}>Transactions</div>
                {transactions?.map((n, i) => {
                  return <Entry data={n} key={i} currency={currency} />;
                })}
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}
